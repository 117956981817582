<script>
import ExportPresentationJobService from '@src/services/ExportPresentationJobService'
import ApiModeVuetable from '@src/components/ApiModeVuetable'
import Layout from '@layouts/main.vue'
import Breadcrumb from '@/src/components/Breadcrumb.vue'
import PageHeader from '@/src/components/PageHeader.vue'
import Pagination from '@src/mixins/pagination.vue'
import swalFeedback from '@src/mixins/swalFeedback'
import vuetableFormatters from '@src/mixins/vuetableFormatters'
import filterVuetable from '@src/mixins/filterVuetable'
import FilterLabelButton from '@/src/components/FilterLabelButton.vue'
import AddLabelButton from '@/src/components/AddLabelButton.vue'
import ConfigLabelButton from '@/src/components/ConfigLabelButton.vue'
import exportPresentationJobConfigModal from '@/src/router/views/exportPresentationJob/exportPresentationJobConfigModal'
import exportPresentationJobFilterModal from '@/src/router/views/exportPresentationJob/exportPresentationJobFilterModal';
import exportPresentationJobModal from '@/src/router/views/exportPresentationJob/exportPresentationJobModal';
import exportPresentationJobFields from '@src/fields/exportPresentationJobFields'
import * as storeFile from "@state/store";

const i18nCommon = 'COMMON'
const i18nKey = 'EXPORT_PRESENTATION_JOBS'

export default {
    metaInfo() {
        return {
            title: this.i18nExportPresentationJob,
        }
    },
    components: {
        ApiModeVuetable,
        Layout,
        Breadcrumb,
        PageHeader,
        FilterLabelButton,
        AddLabelButton,
        ConfigLabelButton,
        exportPresentationJobConfigModal,
        exportPresentationJobFilterModal,
        exportPresentationJobModal,
    },
    mixins: [
        Pagination,
        swalFeedback,
        vuetableFormatters,
        filterVuetable,
        exportPresentationJobFields
    ],
    data() {
        return {
            i18nCommon,
            i18nKey,
            modalIsEdit: false,
            additionalParameters: {
                with: ['file'],
            },
            perPage: 10,
            submitLoading: false,
            css: {},
            sortOrder: [
                {
                    field: 'created_at',
                    direction: 'desc',
                },
            ],
            filters: {
                createdAt: null,
                type: null,
                users: [],
            },
        }
    },
    computed: {
        storeFile() {
            return storeFile
        },
        items() {
            return [
                {
                    text: 'Home',
                    href: '/',
                },
                {
                    text: this.getI18nModified({
                        prefix: i18nCommon,
                        suffix: 'report',
                        modifier: 2,
                    }),
                    active: true,
                },
                {
                    text: this.getI18nModified({
                        prefix: i18nKey,
                        suffix: 'TITLES.export_presentation_job',
                        modifier: 2,
                    }),
                },
            ]
        },

        i18nExportPresentationJob() {
            return this.getI18nModified({
                prefix: i18nKey,
                suffix: 'TITLES.export_presentation_job',
                modifier: 2,
            })
        },
        i18nNewExport() {
            return this.getI18n(i18nKey, 'BUTTONS.new_export')
        },
        i18nExportMaximumPerSlide() {
            return this.getI18n(i18nKey, 'TITLES.export_maximum_per_slide')
        },
        i18nExport() {
            return this.getI18n(i18nKey, 'TITLES.export')
        },
    },
    methods: {
        createAdditionalParameters() {
            return this.formatParameters({
                with: ['file'],
                type: this.filters.type ? String(this.filters.type.value) : null,
                user_id: this.filters.users.map((user) => user.id),
                created_at: this.formatFilterDate(this.filters.createdAt),
            })
        },

        fetch(url, params) {
            return ExportPresentationJobService.fetchVuetable(url, params)
        },

        showConfigModal() {
            this.$refs.configModal.showModal()
        },

        showCreateModal() {
            this.$refs.exportPresentationJobModal.showModal()
        },

        filter(appliedFilters) {
            this.filters = appliedFilters;
            this.additionalParameters = this.createAdditionalParameters()
            this.refreshTable()
        },

        showFilterModal() {
            this.$nextTick(() => this.$refs.exportPresentationJobFilterModal.showModal(this.filters))
        },

        refreshTable() {
            this.$refs.vuetable.refresh()
        },
    },
}
</script>

<template>
    <Layout>
        <div class="col-12">
            <Breadcrumb :items="items" />
            <div class="row mt-3">
                <div class="col-sm-6 mb-3 form-inline"></div>
                <div class="col-sm-6 mb-3">
                    <div class="text-sm-right">
                        <filter-label-button @onClick="showFilterModal" />
                        <config-label-button @onClick="showConfigModal" />
                        <add-label-button :title="i18nNewExport" @onClick="showCreateModal" />
                    </div>
                </div>
            </div>
            <div class="card card-pdv">
                <PageHeader :title="i18nExportPresentationJob" />
                <div class="card-body">
                    <api-mode-vuetable
                        ref="vuetable"
                        :fetch-data="fetch"
                        :fields="exportPresentationJobFields"
                        :per-page="perPage"
                        :additional-parameters="additionalParameters"
                        :sort-order="sortOrder">
                        <template slot="file" slot-scope="props">
                            <div v-if="props.rowData.file && props.rowData.file?.url">
                                <a :href="props.rowData.file.url">
                                    <i class="fe-download" />
                                </a>
                            </div>
                            <div v-if="props.rowData.type === 'link'">
                                <a :href="`https://book.pdvmundo.com.br/${storeFile.default.getters['auth/tenantId']}/${props.rowData.id}`" target="_blank">
                                    <i class="fe-download" />
                                </a>
                            </div>
                        </template>
                    </api-mode-vuetable>
                </div>
            </div>
        </div>
        <export-presentation-job-modal
            ref="exportPresentationJobModal"
            @refresh="refreshTable"
        />
        <exportPresentationJobConfigModal ref="configModal" />
        <export-presentation-job-filter-modal
            ref="exportPresentationJobFilterModal"
            :handle-submit="filter"
        />
    </Layout>
</template>
