<template>
    <form-modal
        ref="formModal"
        :handle-hide="closeModal"
        :handle-open="resetValidation"
        :v="$v.config"
        :title="i18nConfig"
        :size="step === 2 ? 'xl' : 'lg'"
        :in-editing="step === 2"
        :handle-close="handleCancel"
    >
        <template v-slot:form>
            <confirmation-form-modal
                ref="confirmationFormModal"
                :title="i18nConfig"
                :operation="modalOperation"
            />
                <feedback-modal
                    ref="feedbackModal"
                    :title="i18nConfig"
                />
            <b-form-row v-if="step === 1">
                <b-col md="12">
                    <b-form-group
                        :label="getI18n(i18nCommon, 'template')"
                        label-for="template-input"
                        class="label-pdv"
                    >
                        <b-form-radio-group
                            id="radio-group-2"
                            v-model="createOrEdit"
                            name="radio-sub-component"
                        >
                            <b-form-radio value="create" @click="cleanModal">
                                Criar Novo
                            </b-form-radio>
                            <b-form-radio value="template" class="pt-1">
                                Template
                            </b-form-radio>
                        </b-form-radio-group>
                        <b-row v-if="createOrEdit === 'template'">
                            <b-col md="9">
                                <multiSelectWithService
                                    id="template-multiselect"
                                    ref="templateMultiselect"
                                    v-model="config"
                                    :service="'export-presentation-job-configs'"
                                    label="name"
                                    :searchable="false"
                                    :multiple="false"
                                    :parameters="additionalParameters"
                                    :allow-empty="false"
                                    class="mt-2"
                                />
                            </b-col>
                            <b-col md="3" class="mt-1 align-self-center">
                                <b-button
                                    class="btn btn-pdv-gradient-gray"
                                    :disabled="!config || config.id <= 0"
                                    @click="goToStep2"
                                    >{{ `${getI18n('COMMON.update')} ${getI18n('COMMON.template')}` }}
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-form-group>
                </b-col>
            </b-form-row>
            <div v-if="step === 2">
                <b-form-row>
                    <b-col md="12">
                        <!-- Name Input -->
                        <b-form-group
                            :label="i18nName"
                            label-for="name-input"
                            :invalid-feedback="i18nInvalidName"
                            class="required label-pdv"
                        >
                            <b-form-input
                                id="name-input"
                                v-model.trim="$v.config.name.$model"
                                name="name"
                                class="input-pdv-blue"
                                type="text"
                                :state="validateField('name', 'config')"
                                @input="clearResponseError('name', 'config')"
                                @blur="$v.config.name.$touch"
                            ></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-form-row>
                <b-tabs
                    id="export-presentation-job-config-tabs"
                    v-model="tabIndex"
                    class="tabs-pdv"
                >
                    <b-tab :title="i18nLogo">
                        <b-row class="imagePreDiv m-auto">
                            <div class="imageDiv d-flex">
                                <img
                                    v-if="
                                        config &&
                                        config.logo_file &&
                                        config.logo_file.url
                                    "
                                    :src="config.logo_file.url"
                                    fluid
                                    class="m-auto"
                                    alt="Logo Image"
                                />
                                <img
                                    v-else
                                    src="@assets/images/no-image.png"
                                    class="m-auto"
                                    alt="No Image Upload"
                                />
                            </div>
                        </b-row>
                        <b-row class="mt-2 container">
                            <b-form-group
                                label-for="logoFile"
                                :invalid-feedback="getI18n('ERROR_CODES', 'invalid_file')"
                                class="w-100"
                            >
                                <div class="input-bar d-flex">
                                    <b-form-file
                                        ref="logoFile"
                                        v-model="$v.config.logo_image.$model"
                                        required
                                        type="file"
                                        class="input-group"
                                        accept=".jpg, .png, .jpeg"
                                        :browse-text="getI18n('COMMON', 'browse')"
                                        :placeholder="getPlaceholder('logo_image')"
                                        :state="validateField('logo_image', 'config')"
                                        :disabled="imageUploadLoading"
                                        @blur="$v.config.logo_image.$touch"
                                        @input="uploadImage($v.config.logo_image, 'logo_file', 'logo')"
                                    />
                                    <b-button
                                        class="ml-2 btn btn-pdv-gradient-red"
                                        :disabled="!config || config.logo_file === null || imageUploadLoading"
                                        @click="removeImage('logo_file', 'logo')"
                                    >
                                        <b-spinner v-if="deleteLoading" small />
                                        <i v-else class="fe-trash-2" />
                                    </b-button>
                                </div>
                            </b-form-group>
                        </b-row>
                    </b-tab>
                    <b-tab :title="i18nBackground">
                        <b-row class="imagePreDiv m-auto">
                            <div class="imageDiv d-flex">
                                <img
                                    v-if="
                                        config &&
                                        config.background_file &&
                                        config.background_file.url
                                    "
                                    :src="config.background_file.url"
                                    fluid
                                    class="m-auto"
                                    alt="Background Image"
                                />
                                <img
                                    v-else
                                    src="@assets/images/no-image.png"
                                    class="m-auto"
                                    alt="No Image Upload"
                                />
                            </div>
                        </b-row>
                        <b-row class="mt-2 container">
                            <b-form-group
                                label-for="backgroundFile"
                                :invalid-feedback="getI18n('ERROR_CODES', 'invalid_file')"
                                class="w-100"
                            >
                                <br />
                                <div class="input-bar d-flex">
                                    <b-form-file
                                        ref="backgroundFile"
                                        v-model="
                                            $v.config.background_image.$model
                                        "
                                        required
                                        type="file"
                                        class="input-group"
                                        accept=".jpg, .png, .jpeg"
                                        :browse-text="getI18n('COMMON', 'browse')"
                                        :placeholder="getPlaceholder('background_image')"
                                        :state="validateField('background_image', 'config')"
                                        :disabled="imageUploadLoading"
                                        @blur="$v.config.background_image.$touch"
                                        @input="uploadImage($v.config.background_image, 'background_file', 'background')"
                                    />
                                    <b-button
                                        class="ml-2 btn btn-pdv-gradient-red"
                                        :disabled="!config || config.background_file === null || imageUploadLoading"
                                        @click="removeImage('background_file', 'background')"
                                    >
                                        <b-spinner v-if="deleteLoading" small />
                                        <i v-else class="fe-trash-2" />
                                    </b-button>
                                </div>
                            </b-form-group>
                        </b-row>
                    </b-tab>
                    <b-tab :title="i18nCover">
                        <b-row class="imagePreDiv m-auto">
                            <div class="imageDiv d-flex">
                                <img
                                    v-if="
                                        config &&
                                        config.cover_file &&
                                        config.cover_file.url
                                    "
                                    :src="config.cover_file.url"
                                    fluid
                                    class="m-auto"
                                    alt="Cover Image"
                                />
                                <img
                                    v-else
                                    src="@assets/images/no-image.png"
                                    class="m-auto"
                                    alt="No Image Upload"
                                />
                            </div>
                        </b-row>
                        <b-row class="mt-2 container">
                            <b-form-group
                                label-for="coverFile"
                                :invalid-feedback="getI18n('ERROR_CODES', 'invalid_file')"
                                class="w-100"
                            >
                                <br />
                                <div class="input-bar d-flex">
                                    <b-form-file
                                        ref="coverFile"
                                        v-model="$v.config.cover_image.$model"
                                        required
                                        type="file"
                                        class="input-group"
                                        accept=".jpg, .png, .jpeg"
                                        :browse-text="getI18n('COMMON', 'browse')"
                                        :placeholder="getPlaceholder('cover_image')"
                                        :state="validateField('cover_image', 'config')"
                                        :disabled="imageUploadLoading"
                                        @blur="$v.config.cover_image.$touch"
                                        @input="uploadImage($v.config.cover_image, 'cover_file', 'cover')"
                                    />
                                    <b-button
                                        class="ml-2 btn btn-pdv-gradient-red"
                                        :disabled="!config || config.cover_file === null || imageUploadLoading"
                                        @click="removeImage('cover_file', 'cover')"
                                    >
                                        <b-spinner v-if="deleteLoading" small />
                                        <i v-else class="fe-trash-2" />
                                    </b-button>
                                </div>
                            </b-form-group>
                        </b-row>
                    </b-tab>
                    <b-tab :title="i18nBackCover">
                        <b-row class="imagePreDiv m-auto">
                            <div class="imageDiv d-flex">
                                <img
                                    v-if="config && config.back_cover_file && config.back_cover_file.url"
                                    :src="config.back_cover_file.url"
                                    fluid
                                    class="m-auto"
                                    alt="Back Cover Image"
                                />
                                <img
                                    v-else
                                    src="@assets/images/no-image.png"
                                    class="m-auto"
                                    alt="No Image Upload"
                                />
                            </div>
                        </b-row>
                        <b-row class="mt-2 container">
                            <b-form-group
                                label-for="backCoverFile"
                                :invalid-feedback="getI18n('ERROR_CODES', 'invalid_file')"
                                class="w-100"
                            >
                                <br />
                                <div class="input-bar d-flex">
                                    <b-form-file
                                        ref="backCoverFile"
                                        v-model="$v.config.back_cover_image.$model"
                                        required
                                        type="file"
                                        class="input-group"
                                        accept=".jpg, .png, .jpeg"
                                        :browse-text="getI18n('COMMON', 'browse')"
                                        :placeholder="getPlaceholder('back_cover_image')"
                                        :state="validateField('back_cover_image', 'config')"
                                        :disabled="imageUploadLoading"
                                        @blur="$v.config.back_cover_image.$touch"
                                        @input="uploadImage($v.config.back_cover_image, 'back_cover_file', 'back_cover')"
                                    />
                                    <b-button
                                        class="ml-2 btn btn-pdv-gradient-red"
                                        :disabled="!config || config.back_cover_file === null || imageUploadLoading"
                                        @click="removeImage('back_cover_file', 'back_cover')"
                                    >
                                        <b-spinner v-if="deleteLoading" small />
                                        <i v-else class="fe-trash-2" />
                                    </b-button>
                                </div>
                            </b-form-group>
                        </b-row>
                    </b-tab>
                    <b-tab :title="i18nDescriptionSlideTags">
                        <b-form-row>
                            <b-col md="6">
                                <b-form-checkbox
                                    v-model="questionAliasChecked"
                                    name="check-question"
                                    class="label-pdv"
                                >
                                    {{ i18nQuestionAlias }}
                                </b-form-checkbox>
                                <b-form-input
                                    v-if="questionAliasChecked"
                                    id="alias_question-input"
                                    v-model.trim="$v.config.alias_question.$model"
                                    class="input-pdv-blue"
                                    name="alias_question"
                                    type="text"
                                    :state="validateField('alias_question', 'config')"
                                    @input="clearResponseError('alias_question', 'config')"
                                    @blur="$v.config.alias_question.$touch"
                                ></b-form-input>
                            </b-col>
                        </b-form-row>
                        <b-form-row class="pt-2">
                            <b-col md="6">
                                <b-form-checkbox
                                    v-model="userAliasChecked"
                                    name="check-user"
                                    class="label-pdv"
                                >
                                    {{ i18nUserAlias }}
                                </b-form-checkbox>
                                <b-form-input
                                    v-if="userAliasChecked"
                                    id="alias_user-input"
                                    v-model.trim="$v.config.alias_user.$model"
                                    class="input-pdv-blue"
                                    name="alias_user"
                                    type="text"
                                    :state="validateField('alias_user', 'config')"
                                    @input="clearResponseError('alias_user', 'config')"
                                    @blur="$v.config.alias_user.$touch"
                                />
                            </b-col>
                        </b-form-row>
                        <b-form-row class="pt-2">
                            <b-col md="6">
                                <b-form-checkbox
                                    v-model="hourAliasChecked"
                                    name="check-hour"
                                    class="label-pdv"
                                >
                                    {{ i18nHourAlias }}
                                </b-form-checkbox>
                                <b-form-input
                                    v-if="hourAliasChecked"
                                    id="alias_hour-input"
                                    v-model.trim="$v.config.alias_hour.$model"
                                    class="input-pdv-blue"
                                    name="alias_hour"
                                    type="text"
                                    :state="validateField('alias_hour', 'config')"
                                    @input="clearResponseError('alias_hour', 'config')"
                                    @blur="$v.config.alias_hour.$touch"
                                />
                            </b-col>
                        </b-form-row>
                        <b-form-row class="pt-2">
                            <b-col md="6">
                                <b-form-checkbox
                                    v-model="posAliasChecked"
                                    name="check-pos"
                                    class="label-pdv"
                                >
                                    {{ i18nPosAlias }}
                                </b-form-checkbox>
                                <b-form-input
                                    v-if="posAliasChecked"
                                    id="alias_pos-input"
                                    v-model.trim="$v.config.alias_pos.$model"
                                    class="input-pdv-blue"
                                    name="alias_pos"
                                    type="text"
                                    :state="validateField('alias_pos', 'config')"
                                    @input="clearResponseError('alias_pos', 'config')"
                                    @blur="$v.config.alias_pos.$touch"
                                />
                            </b-col>
                        </b-form-row>
                        <b-form-row class="pt-2">
                            <b-col md="6">
                                <b-form-checkbox
                                    v-model="businessUnitAliasChecked"
                                    name="check-business-unit"
                                    class="label-pdv"
                                >
                                    {{ i18nBusinessUnitAlias }}
                                </b-form-checkbox>
                                <b-form-input
                                    v-if="businessUnitAliasChecked"
                                    id="alias_business_unit-input"
                                    v-model.trim="$v.config.alias_business_unit.$model"
                                    class="input-pdv-blue"
                                    name="alias_business_unit"
                                    type="text"
                                    :state="validateField('alias_business_unit', 'config')"
                                    @input="clearResponseError('alias_business_unit', 'config')"
                                    @blur="$v.config.alias_business_unit.$touch"
                                />
                            </b-col>
                        </b-form-row>
                    </b-tab>
                    <b-tab :title="i18nPhotoSlideTags">
                        <b-form-row>
                            <b-col md="6">
                                <b-form-checkbox
                                    v-model="questionFooterAliasChecked"
                                    name="check-question"
                                    class="label-pdv"
                                >
                                    {{ i18nQuestionAlias }}
                                </b-form-checkbox>
                                <b-form-input
                                    v-if="questionFooterAliasChecked"
                                    id="footer_alias_question-input"
                                    v-model.trim="$v.config.footer_alias_question.$model"
                                    class="input-pdv-blue"
                                    name="footer_alias_question"
                                    type="text"
                                    :state="validateField('footer_alias_question', 'config')"
                                    @input="clearResponseError('footer_alias_question', 'config')"
                                    @blur="$v.config.footer_alias_question.$touch"
                                />
                            </b-col>
                        </b-form-row>
                        <b-form-row class="pt-2">
                            <b-col md="6">
                                <b-form-checkbox
                                    v-model="userFooterAliasChecked"
                                    name="check-user"
                                    class="label-pdv"
                                >
                                    {{ i18nUserAlias }}
                                </b-form-checkbox>
                                <b-form-input
                                    v-if="userFooterAliasChecked"
                                    id="footer_alias_user-input"
                                    v-model.trim="$v.config.footer_alias_user.$model"
                                    class="input-pdv-blue"
                                    name="footer_alias_user"
                                    type="text"
                                    :state="validateField('footer_alias_user', 'config')"
                                    @input="clearResponseError('footer_alias_user', 'config')"
                                    @blur="$v.config.footer_alias_user.$touch"
                                />
                            </b-col>
                        </b-form-row>
                        <b-form-row class="pt-2">
                            <b-col md="6">
                                <b-form-checkbox
                                    v-model="dateFooterAliasChecked"
                                    name="check-date"
                                    class="label-pdv"
                                >
                                    {{ i18nHourAlias }}
                                </b-form-checkbox>
                                <b-form-input
                                    v-if="dateFooterAliasChecked"
                                    id="footer_alias_date-input"
                                    v-model.trim="$v.config.footer_alias_date.$model"
                                    class="input-pdv-blue"
                                    name="footer_alias_date"
                                    type="text"
                                    :state="validateField('footer_alias_date', 'config')"
                                    @input="clearResponseError('footer_alias_date', 'config')"
                                    @blur="$v.config.footer_alias_date.$touch"
                                />
                            </b-col>
                        </b-form-row>
                        <b-form-row class="pt-2">
                            <b-col md="6">
                                <b-form-checkbox
                                    v-model="posFooterAliasChecked"
                                    name="check-pos"
                                    class="label-pdv"
                                    >{{ i18nPosAlias }}
                                </b-form-checkbox>
                                <b-form-input
                                    v-if="posFooterAliasChecked"
                                    id="footer_alias_pos-input"
                                    v-model.trim="$v.config.footer_alias_pos.$model"
                                    class="input-pdv-blue"
                                    name="footer_alias_pos"
                                    type="text"
                                    :state="validateField('footer_alias_pos', 'config')"
                                    @input="clearResponseError('footer_alias_pos', 'config')"
                                    @blur="$v.config.footer_alias_pos.$touch"
                                ></b-form-input>
                            </b-col>
                        </b-form-row>
                        <b-form-row class="pt-2">
                            <b-col md="6">
                                <b-form-checkbox
                                    v-model="businessUnitFooterAliasChecked"
                                    name="check-business-unit"
                                    class="label-pdv"
                                >
                                    {{ i18nBusinessUnitAlias }}
                                </b-form-checkbox>
                                <b-form-input
                                    v-if="businessUnitFooterAliasChecked"
                                    id="footer_alias_business_unit-input"
                                    v-model.trim="$v.config.footer_alias_business_unit.$model"
                                    class="input-pdv-blue"
                                    name="footer_alias_business_unit"
                                    type="text"
                                    :state="validateField('footer_alias_business_unit', 'config')"
                                    @input="clearResponseError('footer_alias_business_unit', 'config')"
                                    @blur="$v.config.footer_alias_business_unit.$touch;"
                                />
                            </b-col>
                        </b-form-row>
                        <b-form-row class="pt-2">
                            <b-col md="6">
                                <b-form-checkbox
                                    v-model="productFooterAliasChecked"
                                    name="check-business-unit"
                                    class="label-pdv"
                                >
                                    {{ i18nProductAlias }}
                                </b-form-checkbox>
                                <b-form-input
                                    v-if="productFooterAliasChecked"
                                    id="footer_alias_product-input"
                                    v-model.trim="$v.config.footer_alias_product.$model"
                                    class="input-pdv-blue"
                                    name="footer_alias_product"
                                    type="text"
                                    :state="validateField('footer_alias_product', 'config')"
                                    @input="clearResponseError('footer_alias_product', 'config')"
                                    @blur="$v.config.footer_alias_product.$touch"
                                />
                            </b-col>
                        </b-form-row>
                        <b-form-row class="pt-2">
                            <b-col md="6">
                                <b-form-checkbox
                                    v-model="productCategoryFooterAliasChecked"
                                    name="check-business-unit"
                                    class="label-pdv"
                                >
                                    {{ i18nProductCategoryAlias }}
                                </b-form-checkbox>
                                <b-form-input
                                    v-if="productCategoryFooterAliasChecked"
                                    id="footer_alias_product_category-input"
                                    v-model.trim="$v.config.footer_alias_product_category.$model"
                                    class="input-pdv-blue"
                                    name="footer_alias_product_category"
                                    type="text"
                                    :state="validateField('footer_alias_product_category', 'config')"
                                    @input="clearResponseError('footer_alias_product_category', 'config')"
                                    @blur="$v.config.footer_alias_product_category.$touch;"
                                />
                            </b-col>
                        </b-form-row>
                    </b-tab>
                </b-tabs>
            </div>
        </template>
        <template v-slot:footer>
            <cancel-button
                ref="cancelButton"
                child-class="col-sm-4 float-left"
                :disabled="submitLoading || imageUploadLoading || deleteLoading"
                @onClick="handleCancel"
            />
            <save-button
                v-if="step === 2 || config.id > 0"
                ref="okButton"
                child-class="col-sm-4 float-right ml-3"
                :busy="submitLoading"
                :disabled="imageUploadLoading || deleteLoading"
                @onClick="handleOk"
            />
            <save-button
                v-else
                ref="okButton"
                child-class="col-sm-4 float-right ml-3"
                :busy="submitLoading"
                :disabled="imageUploadLoading || deleteLoading"
                :title="i18nNewTemplate"
                @onClick="goToStep2"
            />
        </template>
    </form-modal>
</template>

<script>
import { required, minLength, requiredIf } from 'vuelidate/lib/validators'
import FormModal from '@components/FormModal'
import ConfirmationFormModal from '@src/components/ConfirmationFormModal'
import FeedbackModal from '@src/components/FeedbackModal'
import validation from '@src/mixins/validation'
import FilesService from '@/src/services/FilesService'
import swalFeedback from '@src/mixins/swalFeedback'
import ExportPresentationJobConfigService from '@/src/services/ExportPresentationJobConfigService'
import multiSelectWithService from '@src/components/multiSelectWithService'
import CancelButton from '@/src/components/CancelButton'
import SaveButton from '@/src/components/SaveButton'

const i18nCommon = 'COMMON'
const i18nKey = 'EXPORT_PRESENTATION_JOBS'

const isTrueImage = (value, vm) => {
    if (!value || !value.type) {
        return true
    }
    return value.type.startsWith('image')
}

export default {
    components: {
        FormModal,
        ConfirmationFormModal,
        FeedbackModal,
        multiSelectWithService,
        CancelButton,
        SaveButton
    },
    mixins: [validation, swalFeedback],
    data() {
        return {
            i18nCommon,
            i18nKey,
            config: {
                id: 0,
                name: '',
                background: '',
                logo: '',
                cover: '',
                back_cover: '',
                alias_question: 'Questão',
                alias_hour: 'Data',
                alias_pos: 'Ponto de Venda',
                alias_business_unit: 'Unidade de Negócio',
                alias_user: 'Usuário',
                footer_alias_user: 'Usuário',
                footer_alias_date: 'Data',
                footer_alias_pos: 'Ponto de Venda',
                footer_alias_business_unit: 'Unidade de Negócio',
                footer_alias_question: 'Questão',
                footer_alias_product: 'Produto',
                footer_alias_product_category: 'Categoria',
                active: true,
                cover_file: {},
                back_cover_file: {},
                background_file: {},
                logo_file: {},
                cover_image: null,
                back_cover_image: null,
                background_image: null,
                logo_image: null,
            },
            tabIndex: 0,
            imageUploadLoading: false,
            submitLoading: false,
            deleteLoading: false,
            step: 1,
            createOrEdit: 'create',
            additionalParameters: {
                with: [
                    'backgroundFile',
                    'logoFile',
                    'coverFile',
                    'backCoverFile',
                ],
            },
            userAliasChecked: true,
            questionAliasChecked: true,
            hourAliasChecked: true,
            posAliasChecked: true,
            businessUnitAliasChecked: true,
            questionFooterAliasChecked: true,
            userFooterAliasChecked: true,
            dateFooterAliasChecked: true,
            posFooterAliasChecked: true,
            businessUnitFooterAliasChecked: true,
            productFooterAliasChecked: true,
            productCategoryFooterAliasChecked: true,
        }
    },
    validations: {
        config: {
            name: { required, minLength: minLength(2) },
            alias_user: {
                required: requiredIf(function (modal) {
                    return this.userAliasChecked
                }),
            },
            alias_question: {
                required: requiredIf(function (modal) {
                    return this.questionAliasChecked
                }),
            },
            alias_hour: {
                required: requiredIf(function (modal) {
                    return this.hourAliasChecked
                }),
            },
            alias_pos: {
                required: requiredIf(function (modal) {
                    return this.posAliasChecked
                }),
            },
            alias_business_unit: {
                required: requiredIf(function (modal) {
                    return this.businessUnitAliasChecked
                }),
            },
            footer_alias_question: {
                required: requiredIf(function () {
                    return this.questionFooterAliasChecked
                }),
            },
            footer_alias_user: {
                required: requiredIf(function () {
                    return this.userFooterAliasChecked
                }),
            },
            footer_alias_date: {
                required: requiredIf(function () {
                    return this.dateFooterAliasChecked
                }),
            },
            footer_alias_pos: {
                required: requiredIf(function () {
                    return this.posFooterAliasChecked
                }),
            },
            footer_alias_business_unit: {
                required: requiredIf(function () {
                    return this.businessUnitFooterAliasChecked
                }),
            },
            footer_alias_product: {
                required: requiredIf(function () {
                    return this.productFooterAliasChecked
                }),
            },
            footer_alias_product_category: {
                required: requiredIf(function () {
                    return this.productCategoryFooterAliasChecked
                }),
            },
            background_image: { isTrueImage },
            logo_image: { isTrueImage },
            cover_image: { isTrueImage },
            back_cover_image: { isTrueImage },
        },
    },

    computed: {
        modalOperation() {
            return this.config.id > 0 ? 'edited' : 'created'
        },
        i18nNewTemplate() {
            return this.getI18n(i18nKey, 'BUTTONS.new_template')
        },
        i18nConfig() {
            return this.getI18n(i18nKey, 'TITLES.config')
        },
        i18nLogo() {
            return this.getI18n(i18nKey, 'TITLES.logo')
        },
        i18nBackground() {
            return this.getI18n(i18nKey, 'TITLES.background')
        },
        i18nCover() {
            return this.getI18n(i18nKey, 'TITLES.cover')
        },
        i18nBackCover() {
            return this.getI18n(i18nKey, 'TITLES.back_cover')
        },
        i18nName() {
            return this.getI18n(i18nCommon, 'name')
        },
        i18nInvalidName() {
            return this.getI18n('ERROR_CODES.invalid_name')
        },
        i18nDescriptionSlideTags() {
            return this.getI18n(i18nKey, 'TITLES.slide_description_tag')
        },
        i18nUserAlias() {
            return this.getI18n('USERS', 'TITLES.user')
        },
        i18nQuestionAlias() {
            return this.getI18n(i18nKey, 'TITLES.question_alias')
        },
        i18nHourAlias() {
            return this.getI18n(i18nKey, 'TITLES.hour_alias')
        },
        i18nPosAlias() {
            return this.getI18n(i18nKey, 'TITLES.pos_alias')
        },
        i18nBusinessUnitAlias() {
            return this.getI18n(i18nKey, 'TITLES.business_unit_alias')
        },
        i18nProductAlias() {
            return this.getI18n(i18nKey, 'TITLES.product_alias')
        },
        i18nProductCategoryAlias() {
            return this.getI18n(i18nKey, 'TITLES.product_category_alias')
        },
        i18nPhotoSlideTags() {
            return this.getI18n(i18nKey, 'TITLES.photo_slide_tag')
        },
    },
    watch: {
        createOrEdit(newVal) {
            if (newVal === 'create') {
                this.cleanModal()
            } else {
                this.getConfig()
            }
        },
        userAliasChecked(newVal) {
            this.config.alias_user = newVal ? '' : null
        },
        questionAliasChecked(newVal) {
            this.config.alias_question = newVal ? '' : null
        },
        hourAliasChecked(newVal) {
            this.config.alias_hour = newVal ? '' : null
        },
        posAliasChecked(newVal) {
            this.config.alias_pos = newVal ? '' : null
        },
        businessUnitAliasChecked(newVal) {
            this.config.alias_business_unit = newVal ? '' : null
        },
        questionFooterAliasChecked(newVal) {
            this.config.footer_alias_question = newVal ? '' : null
        },
        userFooterAliasChecked(newVal) {
            this.config.footer_alias_user = newVal ? '' : null
        },
        dateFooterAliasChecked(newVal) {
            this.config.footer_alias_date = newVal ? '' : null
        },
        posFooterAliasChecked(newVal) {
            this.config.footer_alias_pos = newVal ? '' : null
        },
        businessUnitFooterAliasChecked(newVal) {
            this.config.footer_alias_business_unit = newVal ? '' : null
        },
        productFooterAliasChecked(newVal) {
            this.config.footer_alias_product = newVal ? '' : null
        },
        productCategoryFooterAliasChecked(newVal) {
            this.config.footer_alias_product_category = newVal ? '' : null
        },
    },
    methods: {
        // modal
        goToStep2() {
            this.$nextTick(() => {
                this.userAliasChecked = this.config.alias_user !== null
                this.questionAliasChecked = this.config.alias_question !== null
                this.posAliasChecked = this.config.alias_pos !== null
                this.hourAliasChecked = this.config.alias_hour !== null
                this.businessUnitAliasChecked = this.config.alias_business_unit !== null
                this.questionFooterAliasChecked = this.config.footer_alias_question !== null
                this.userFooterAliasChecked = this.config.footer_alias_user !== null
                this.dateFooterAliasChecked = this.config.footer_alias_date !== null
                this.posFooterAliasChecked = this.config.footer_alias_pos !== null
                this.businessUnitFooterAliasChecked = this.config.footer_alias_business_unit !== null
                this.productFooterAliasChecked = this.config.footer_alias_product !== null
                this.productCategoryFooterAliasChecked = this.config.footer_alias_product_category !== null
                this.step = 2
            })
        },
        async showModal() {
            this.step = 1
            this.tabIndex = 0
            this.submitLoading = false
            this.deleteLoading = false
            this.imageUploadLoading = false
            await this.getConfig()
            this.$nextTick(() => this.$refs.formModal.show())
        },

        getPlaceholder(property) {
            return this.config &&
                this.config[property] &&
                this.config[property].original_name
                ? this.config[property].original_name
                : this.getI18n('COMMON', 'no_file_chosen')
        },

        async getConfig() {
            const response = await ExportPresentationJobConfigService.fetchAll({
                ...this.additionalParameters,
                active: true,
            })
            if (response && response.data && response.data.data[0]) {
                Object.assign(this.config, response.data.data[0])
                this.createOrEdit = 'template'
            }
        },

        async uploadImage(validationImage, file, hash) {
            validationImage.$touch()
            if (validationImage && !validationImage.$invalid && !this.imageUploadLoading) {
                this.imageUploadLoading = true
                const response = await FilesService.upload(validationImage.$model, 'config_photos')
                    .then((response) => response)
                    .catch((error) => error)

                const statusCode = response.status.toString()

                if (statusCode.charAt(0) === '2') {
                    validationImage.$model = null
                    this.config[file] = response.data.data
                    this.config[hash] = response.data.data.hash
                }
                this.imageUploadLoading = false
            }
        },

        removeImage(image, imageType) {
            this.deleteLoading = true
            this.config[image] = null
            this.config[imageType] = null
            this.deleteLoading = false
        },

        resetValidation() {
            this.$nextTick(() => this.$v.$reset())
            this.clearAllResponseError()
        },

        closeModal() {
            this.step = 1
            this.cleanModal()
        },

        cleanModal() {
            this.replaceModalData({
                id: 0,
                name: '',
                background: '',
                logo: '',
                cover: '',
                back_cover: '',
                alias_question: this.i18nQuestionAlias,
                alias_hour: this.i18nHourAlias,
                alias_pos: this.i18nPosAlias,
                alias_business_unit: this.i18nBusinessUnitAlias,
                alias_user: this.i18nUserAlias,
                footer_alias_user: this.i18nUserAlias,
                footer_alias_date: this.i18nHourAlias,
                footer_alias_pos: this.i18nPosAlias,
                footer_alias_business_unit: this.i18nBusinessUnitAlias,
                footer_alias_question: this.i18nQuestionAlias,
                footer_alias_product: this.i18nProductAlias,
                footer_alias_product_category: this.i18nProductCategoryAlias,
                active: true,
                cover_file: {},
                back_cover_file: {},
                background_file: {},
                logo_file: {},
                cover_image: null,
                back_cover_image: null,
                background_image: null,
                logo_image: null,
            })
        },

        replaceModalData(data) {
            Object.assign(this.config, data)
        },

        handleCancel() {
            if (this.step === 2) {
                this.$refs.confirmationFormModal.showModal(true, this.handleClose)
            }
            else {
                this.handleClose(true)
            }
        },
        handleClose(close) {
            if (!close) {
                return;
            }
            this.resetValidation()
            this.$refs.formModal.hide()
        },

        handleOk() {
            this.$v.config.$touch()
            if (!this.$v.config.$invalid && !this.submitLoading) {
                this.$refs.confirmationFormModal.showModal(false, this.handleSubmit)
            }
        },

        async handleSubmit(submit) {
            if (!submit) {
                return;
            }

            this.submitLoading = true

            const operation =
                this.config.id > 0
                    ? ExportPresentationJobConfigService.update(
                          this.config.id,
                          this.config
                      )
                    : ExportPresentationJobConfigService.create(this.config)
            const response = await operation
                .then((response) => response)
                .catch((error) => error)
            const statusCode = response.status.toString()
            if (statusCode.charAt(0) === '2') {
                this.resetValidation()
                this.$refs.formModal.hide()
                this.positiveFeedback(this.i18nConfig, this.modalOperation, 2)
            } else if (statusCode === '422') {
                this.modalResponseErrors = response.data.errors
                this.warningFeedbackApi(this.i18nConfig, response.data.errors)
            } else {
                this.$refs.feedbackModal.showModal(statusCode, response.data.message)
            }
            this.submitLoading = false
        },

        unsuccessfulOperation() {
            this.negativeFeedback()
        },
    },
}
</script>

<style>
.imagePreDiv {
    width: 100%;
    height: 400px;
}
.imageDiv {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 400px;
    border: 1px solid #5899ef;
    box-sizing: border-box;
    border-radius: 12px;
}
.imageDiv > img {
    max-width: 100%;
    max-height: 100%;
}
.img-fluid {
    max-height: 100%;
}
</style>
